import React from "react";
import { Link } from "react-router-dom";
import * as Links from "../../library/Links";
import { fmtMoneyUSD } from "../../library/Utilities";

const buildTextLink = (finance, addDescr) => {
  // URL
  let url = null;
  if (finance.type === "FINANCE") {
    url = Links.financeFor(finance.id);
  } else if (finance.type === "HEALTHCARE") {
    url = Links.healthcareCardById(finance.id);
  } else if (finance.type === "GIFTCARD") {
    url = Links.giftCardForId(finance.id);
  }
  // Content
  return (
    <div>
      {url ? <Link to={url}>{finance.name}</Link> : <span>{finance.name}</span>}
      {addDescr && finance.description && (
        <div className="description">{finance.description}</div>
      )}
    </div>
  );
};

export default function Financing(props) {
  const finances = props.finances || [];
  const isTransfer = props.isTransfer;
  // Transfer
  if (isTransfer) {
    const _finances = finances.filter((f) => f.primary);
    if (_finances.length > 0) {
      const finance = _finances[0];
      return buildTextLink(finance, true);
    }
  }
  // When 1
  if (finances.length === 1) {
    const finance = finances[0];
    return buildTextLink(finance, true);
  }
  // Othwerise
  return (
    <table className="table table-sm" style={{ marginBottom: "0" }}>
      <tbody>
        {finances.map((finance) => (
          <tr key={"f-" + finance.id}>
            <td className="text-right">{fmtMoneyUSD(finance.amount)}</td>
            <td>{buildTextLink(finance, false)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
