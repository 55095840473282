import React, { Component } from "react";
import { doHttpGet, doHttpPost } from "../../services/WebService";
import { healthcareCardEditUrl, healthcareCardUrl } from "../../library/Urls";
import Modal from "react-bootstrap/Modal";
import Checkbox from "../common/Checkbox";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { isValidUsMoney, toUsMoney } from "../../library/Utilities";
import NumberField from "../common/NumberField";

export default class EditHealthcareCard extends Component {
  constructor(props) {
    super(props);
    // Initial state
    this.state = {
      show: props.show,
      onClose: props.onClose,
      onSave: props.onSave,
      healthcareCardId: props.healthcareCardId,
      errorMessage: null,
      actionsEnabled: false,
    };
  }

  componentDidMount = (evt) => {
    // Initial data
    doHttpGet({
      url: healthcareCardEditUrl,
      params: {
        id: this.state.healthcareCardId,
      },
      onSuccess: (data) => {
        this.setState({
          editInfo: {
            ...data,
            initialAmount:
              data.initialAmount === null
                ? "0.00"
                : data.initialAmount.toFixed(2),
            name: data.name || "",
            issuer: data.issuer || "",
            number: data.number || "",
          },
          actionsEnabled: true,
          errorMessage: null,
        });
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  validateHealthcareCard = (values) => {
    // Errors
    const errors = {};
    // Name
    if (!values.name) {
      errors.name = "Card name is required";
    }
    // Issuer
    if (!values.issuer) {
      errors.issuer = "Card issuer is required";
    }
    // Number
    if (!values.number) {
      errors.number = "Card number is required";
    }
    // Initial amount
    if (!values.initialAmount) {
      errors.initialAmount = "Initial amount is required";
    } else {
      if (!isValidUsMoney(values.initialAmount)) {
        errors.initialAmount = "Invalid monetary amount";
      } else {
        const _amount = toUsMoney(values.initialAmount);
        if (_amount <= 0.0) {
          errors.amount = "Amount must be more than 0.0";
        }
      }
    }
    // Whether any errors
    let hasErrors = false;
    for (var attr in errors) {
      if (errors.hasOwnProperty(attr)) {
        hasErrors = true;
        break;
      }
    }
    if (!hasErrors) {
      // Save form values in state
      this.setState({
        healthcareCardInfo: values,
        hasValidationErrors: false,
        actionsEnabled: true,
      });
    } else {
      this.setState({
        hasValidationErrors: true,
        actionsEnabled: false,
      });
    }
    // Errors
    return errors;
  };

  saveHealthcareCard = () => {
    // Disable actions
    this.setState({
      actionsEnabled: false,
    });
    // Build request
    const requestInfo = {
      id: this.state.healthcareCardId,
      ...this.state.healthcareCardInfo,
    };
    // Post
    doHttpPost({
      url: healthcareCardUrl,
      body: requestInfo,
      onSuccess: (response) => {
        this.state.onSave(response.id);
      },
      rawError: true,
      onError: (error) => {
        this.setState({
          actionsEnabled: true,
        });
        const httpStatus = error.response.status;
        if (httpStatus === 400) {
          let htmlError = "<ol>";
          error.response.data.errors.forEach((e) => {
            htmlError += "<li>" + e.field + ": " + e.defaultMessage + "</li>";
          });
          htmlError += "</ol>";
          this.setState({
            errorMessage: htmlError,
          });
        } else if (httpStatus === 500) {
          const errorMessage = error.response.data.message;
          this.setState({
            errorMessage: errorMessage,
          });
        }
      },
    });
  };

  render() {
    // Info
    const editInfo = this.state.editInfo;
    if (!editInfo) return null;
    // Whether editing
    const isEditing = this.state.healthcareCardId != null;
    // Form field values
    const { enabled, name, issuer, number, initialAmount } = editInfo;
    // Render
    return (
      <Modal
        show={this.state.show}
        onHide={this.state.onClose}
        centered
        size="sm"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditing ? "Update" : "Create"} Healthcare Card
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.errorMessage && (
            <div
              className="alert alert-warning"
              style={{
                marginBottom: "10px",
              }}
            >
              {this.state.errorMessage}
            </div>
          )}
          <Formik
            initialValues={{ enabled, name, issuer, number, initialAmount }}
            validate={this.validateHealthcareCard}
            validateOnBlur={true}
            validateOnChange={true}
            enableReinitialize={true}
          >
            {(props) => {
              return (
                <Form>
                  <fieldset className="form-group">
                    <label className="control-label">Card Name</label>
                    <Field
                      type="text"
                      name="name"
                      className="form-control"
                    ></Field>
                    <ErrorMessage
                      name="name"
                      className="alert alert-warning"
                      component="div"
                    />
                  </fieldset>
                  <fieldset className="form-group">
                    <label className="control-label">Card Issuer</label>
                    <Field
                      type="text"
                      name="issuer"
                      className="form-control"
                    ></Field>
                    <ErrorMessage
                      name="issuer"
                      className="alert alert-warning"
                      component="div"
                    />
                  </fieldset>
                  <fieldset className="form-group">
                    <label className="control-label">Card Number</label>
                    <Field
                      type="text"
                      name="number"
                      className="form-control"
                    ></Field>
                    <ErrorMessage
                      name="number"
                      className="alert alert-warning"
                      component="div"
                    />
                  </fieldset>
                  <fieldset className="form-group required">
                    <label className="control-label">Initial Amount</label>
                    <Field component={NumberField} name="initialAmount"></Field>
                    <ErrorMessage
                      name="initialAmount"
                      className="alert alert-warning"
                      component="div"
                    />
                  </fieldset>
                  <fieldset className="form-group">
                    <Field
                      component={Checkbox}
                      name="enabled"
                      id="enabled"
                      label="This card is enabled"
                    />
                  </fieldset>
                </Form>
              );
            }}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              className="btn btn-md btn-primary"
              disabled={
                !this.state.actionsEnabled || this.state.hasValidationErrors
              }
              onClick={this.saveHealthcareCard}
            >
              Save Healthcare Card
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}
