import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { doHttpGet, doHttpDelete } from "../../services/WebService";
import {
  healthcareCardUrl,
  healthcareDisbursementUrl,
} from "../../library/Urls";
import PageNotFound from "../common/PageNotFound";
import Money from "../common/Money";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import TransactionListings from "../transactions/TransactionListings";
import Disbursements from "./Disbursements";
import NotificationSystem from "react-notification-system";
import ConfirmDialog from "../common/ConfirmDialog";
import { fmtMoneyUSD } from "../../library/Utilities";
import EditDisbursement from "./EditDisbursement";
import EditHealthcareCard from "./EditHealthcareCard";

export default class HealthcareCard extends Component {
  notificationSystem = React.createRef();

  constructor(props) {
    super(props);
    // Initial state
    this.state = {
      info: null,
      healthcareCardId: props.match.params.healthcareCardId,
      confirmDelete: false,
    };
  }

  getInfo = () => {
    doHttpGet({
      url: healthcareCardUrl,
      params: {
        id: this.state.healthcareCardId,
      },
      onSuccess: (info) => {
        this.setState({
          info: info,
          errorMessage: null,
          healthcareCardName: info.name,
        });
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  componentDidMount = () => {
    this.getInfo(null);
  };

  confirmDisbursementDelete = (id, disburser, amount) => {
    this.setState({
      showDisbursementDeleteConfirm: true,
      disbursementId: id,
      disbursementFrom: disburser,
      disbursementAmt: amount,
    });
  };

  closeDisbursementDeleteConfirm = () => {
    this.setState({
      showDisbursementDeleteConfirm: false,
    });
  };

  deleteDisbursement = () => {
    doHttpDelete({
      url: healthcareDisbursementUrl,
      params: {
        id: this.state.disbursementId,
      },
      onSuccess: (response) => {
        // Close confirmation
        this.closeDisbursementDeleteConfirm();
        // Refresh
        this.getInfo();
        // Notification
        this.deletionNotification(response.success);
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  deletionNotification = (success) => {
    const notification = this.notificationSystem.current;
    notification.addNotification({
      title: "Disbursement Deleted",
      message:
        "The " +
        fmtMoneyUSD(this.state.disbursementAmt) +
        " disbursement from " +
        this.state.disbursementFrom +
        " was " +
        (success ? "" : "not ") +
        "deleted successfully",
      level: success ? "success" : "error",
      position: "br",
      autoDismiss: 5,
    });
  };

  showDisbursementEditor = (id) => {
    this.setState({
      showDisbursementEditor: true,
      disbursementId: id,
    });
  };

  closeDisbursementEditor = () => {
    this.setState({
      showDisbursementEditor: false,
    });
  };

  onSaveDisbursement = (id) => {
    this.setState({
      showDisbursementEditor: false,
      disbursementId: id,
    });
    this.getInfo();
  };

  showHealthcareCardEditor = () => {
    this.setState({
      showHealthcareCardEditor: true,
    });
  };

  closeHealthcareEditor = () => {
    this.setState({
      showHealthcareCardEditor: false,
    });
  };

  onSaveHealthcareCard = () => {
    this.setState({
      showHealthcareCardEditor: false,
    });
    this.getInfo();
  };

  render() {
    // Info
    const { info, errorMessage } = this.state;
    if (!info) {
      if (errorMessage) {
        return <PageNotFound title="Healthcare Card" message={errorMessage} />;
      }
      return null;
    }
    // Render
    return (
      <div className="page-content">
        <div className="row" style={{ marginBottom: "15px" }}>
          <div className="col-4">
            <h2>{info.name}</h2>
            <span className="gzn-detail-flag" style={{ marginLeft: 0 }}>
              Healthcare Card
            </span>
          </div>
          <div className="col-5">
            <HealthcareDescription
              info={info}
              fxEdit={this.showHealthcareCardEditor}
            />
          </div>
          <div className="col-3 text-right">
            <Money amount={info.balance} className="amount-lg" />
          </div>
        </div>

        <Tabs defaultActiveKey="transactions" id="financial-account-tab">
          <Tab
            eventKey="transactions"
            title={"Transactions (" + info.transactions.length + ")"}
          >
            <TransactionListings
              transactions={info.transactions}
              displayFinancing={false}
            />
          </Tab>
          <Tab
            eventKey="disbursement"
            title={"Disbursements (" + info.disbursements.length + ")"}
          >
            <Disbursements
              disbursements={info.disbursements}
              fxDeleteConfirm={this.confirmDisbursementDelete}
              fxShowEditor={this.showDisbursementEditor}
            />
          </Tab>
        </Tabs>

        {this.state.showHealthcareCardEditor && (
          <EditHealthcareCard
            show={this.state.showHealthcareCardEditor}
            onSave={this.onSaveHealthcareCard}
            onClose={this.closeHealthcareEditor}
            healthcareCardId={this.state.healthcareCardId}
          />
        )}

        {this.state.showDisbursementEditor && (
          <EditDisbursement
            show={this.state.showDisbursementEditor}
            onSave={this.onSaveDisbursement}
            onClose={this.closeDisbursementEditor}
            disbursementId={this.state.disbursementId}
            healthcareCardId={this.state.healthcareCardId}
          />
        )}

        {this.state.showDisbursementDeleteConfirm && (
          <ConfirmDialog
            show={this.state.showDisbursementDeleteConfirm}
            handleAction={this.deleteDisbursement}
            handleClose={this.closeDisbursementDeleteConfirm}
            title="Delete Disbursement?"
            message={
              "The disbursement from " +
              this.state.disbursementFrom +
              " in the amount of <b>" +
              fmtMoneyUSD(this.state.disbursementAmt) +
              "</b> will immediately be deleted, affecting healthcare card balances."
            }
            question="Are you sure you want to delete the disbursement?"
            btnAction="Yes, Delete"
          />
        )}

        <NotificationSystem ref={this.notificationSystem} />
      </div>
    );
  }
}

function HealthcareDescription(props) {
  const info = props.info;
  // Description
  return (
    <div className="row">
      <div className="col-8 fin-description">
        <div>
          <b>Issuer:</b> {info.issuer}
        </div>
        <div>
          <b>Number:</b> {info.number}
        </div>
        <div>
          <b>Enabled:</b> {info.enabled ? "Yes" : "No"}
        </div>
      </div>
      <div className="col-4">
        <button
          type="button"
          className="btn btn-primary btn-sm"
          onClick={props.fxEdit}
          style={{ marginRight: "10px" }}
        >
          <FontAwesomeIcon icon={faEdit} /> Edit
        </button>
        {info.deletable && (
          <button
            type="button"
            className="btn btn-danger btn-sm"
            onClick={props.fxDelete}
          >
            <FontAwesomeIcon icon={faTrash} /> Delete
          </button>
        )}
      </div>
    </div>
  );
}
