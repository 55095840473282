import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field } from "formik";
import { doHttpGet } from "../../services/WebService";
import { collegeSavingsTypesUrl } from "../../library/Urls";

export default class CollegeConversionDlg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: props.show,
      handleClose: props.onClose,
      handleSave: props.onSave,
      financeName: props.financeName,
      financeDesc: props.financeDesc,
      savingsTypeList: [],
      errorMessage: null,
      actionsEnabled: false,
    };
  }

  componentDidMount = (evt) => {
    doHttpGet({
      url: collegeSavingsTypesUrl,
      onSuccess: (savingsTypeList) => {
        this.setState({
          savingsTypeList: savingsTypeList,
          actionsEnabled: true,
          errorMessage: null,
        });
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  fxConversion = (values) => {
    // Conversion data
    const conversionInfo = {
      savings_type: values.savings_type,
    };
    // Perform conversion
    this.state.handleSave(conversionInfo);
  };

  fxValidateInput = (values) => {
    const errors = {};
    // Required inputs
    if (!values.savings_type) {
      errors.savings_type = "Select a Savings Type";
    }
    // Errors
    return errors;
  };

  render() {
    // Form field values
    const {
      savingsTypeList,
      actionsEnabled,
      errorMessage,
      financeName,
      financeDesc,
    } = this.state;
    const savings_type = "Individual 529 Plan";

    return (
      <Modal
        show={this.state.show}
        onHide={this.state.handleClose}
        centered
        size="sm"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Convert To College</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorMessage && (
            <div
              className="alert alert-warning"
              style={{
                marginBottom: "5px",
              }}
            >
              {errorMessage}
            </div>
          )}
          <div
            className="alert alert-danger"
            style={{
              marginBottom: "5px",
            }}
          >
            The financial account <b>{financeName}</b> ({financeDesc}) will be
            converted to a COLLEGE SAVINGS account. This process is not
            reversible, and the change will be immediate.
          </div>
          <Formik
            initialValues={{
              savings_type,
            }}
            onSubmit={this.fxConversion}
            validate={this.fxValidateInput}
            validateOnBlur={true}
            validateOnChange={false}
            enableReinitialize={true}
          >
            {(props) => (
              <Form>
                <fieldset className="form-group required">
                  <label className="control-label">College Savings Type</label>
                  <Field
                    className="form-control"
                    as="select"
                    name="savings_type"
                  >
                    {savingsTypeList.map((pt) => (
                      <option key={pt} value={pt}>
                        {pt}
                      </option>
                    ))}
                  </Field>
                </fieldset>
                <Modal.Footer>
                  <button
                    className={"btn btn-primary"}
                    type="submit"
                    disabled={!actionsEnabled}
                  >
                    Perform Conversion
                  </button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  }
}
