import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field } from "formik";
import { doHttpGet } from "../../services/WebService";
import { retirementPlanTypesUrl } from "../../library/Urls";

export default class RetirementConversionDlg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: props.show,
      handleClose: props.onClose,
      handleSave: props.onSave,
      financeName: props.financeName,
      financeDesc: props.financeDesc,
      planTypeList: [],
      errorMessage: null,
      actionsEnabled: false,
    };
  }

  componentDidMount = (evt) => {
    doHttpGet({
      url: retirementPlanTypesUrl,
      onSuccess: (planTypeList) => {
        this.setState({
          planTypeList: planTypeList,
          actionsEnabled: true,
          errorMessage: null,
        });
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  fxConversion = (values) => {
    // Conversion data
    const conversionInfo = {
      plan_type: values.plan_type,
    };
    // Perform conversion
    this.state.handleSave(conversionInfo);
  };

  fxValidateInput = (values) => {
    const errors = {};
    // Required inputs
    if (!values.plan_type) {
      errors.plan_type = "Select a Plan Type";
    }
    // Errors
    return errors;
  };

  render() {
    // Form field values
    const {
      planTypeList,
      actionsEnabled,
      errorMessage,
      financeName,
      financeDesc,
    } = this.state;
    const plan_type = "401(k)";

    return (
      <Modal
        show={this.state.show}
        onHide={this.state.handleClose}
        centered
        size="sm"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Convert To Retirement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorMessage && (
            <div
              className="alert alert-warning"
              style={{
                marginBottom: "5px",
              }}
            >
              {errorMessage}
            </div>
          )}
          <div
            className="alert alert-danger"
            style={{
              marginBottom: "5px",
            }}
          >
            The financial account <b>{financeName}</b> ({financeDesc}) will be
            converted to a Retirement financial account. This process is not
            reversible, and the change will be immediate. Best effort will be
            done to move all data associated with this account to the new
            retirement account.
          </div>
          <Formik
            initialValues={{
              plan_type,
            }}
            onSubmit={this.fxConversion}
            validate={this.fxValidateInput}
            validateOnBlur={true}
            validateOnChange={false}
            enableReinitialize={true}
          >
            {(props) => (
              <Form>
                <fieldset className="form-group required">
                  <label className="control-label">Retirement Plan Type</label>
                  <Field className="form-control" as="select" name="plan_type">
                    {planTypeList.map((pt) => (
                      <option key={pt} value={pt}>
                        {pt}
                      </option>
                    ))}
                  </Field>
                </fieldset>
                <Modal.Footer>
                  <button
                    className={"btn btn-primary"}
                    type="submit"
                    disabled={!actionsEnabled}
                  >
                    Perform Conversion
                  </button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  }
}
