import React, { Component } from "react";
import { doHttpGet, doHttpDelete } from "../../services/WebService";
import { healthcareInfoUrl, healthcareCardUrl } from "../../library/Urls";
import PageNotFound from "../common/PageNotFound";
import { Link } from "react-router-dom";
import * as Links from "../../library/Links";
import Money from "../common/Money";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Tabs, Tab } from "react-bootstrap";
import { fmtMoneyUSD } from "../../library/Utilities";
import TransactionListings from "../transactions/TransactionListings";
import classnames from "classnames";
import EditHealthcareCard from "./EditHealthcareCard";
import ConfirmDialog from "../common/ConfirmDialog";
import NotificationSystem from "react-notification-system";

export default class Healthcare extends Component {
  notificationSystem = React.createRef();

  // Constructor
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      confirmCardDelete: false,
    };
  }

  getHealthcareInfo = () => {
    doHttpGet({
      url: healthcareInfoUrl,
      onSuccess: (data) => {
        this.setState({
          info: data,
          actionsEnabled: true,
          errorMessage: null,
        });
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  componentDidMount = () => {
    this.getHealthcareInfo();
  };

  showHealthcareCardEditor = (cardId) => {
    this.setState({
      showHealthcareCardEditor: true,
      healthcareCardId: cardId,
    });
  };

  closeHealthcareEditor = () => {
    this.setState({
      showHealthcareCardEditor: false,
    });
  };

  onSaveHealthcareCard = (cardId) => {
    this.setState({
      showHealthcareCardEditor: false,
      healthcareCardId: cardId,
    });
    this.getHealthcareInfo();
  };

  showHealthcareCardDeleteConfirm = (cardId, cardName) => {
    this.setState({
      showHealthcareCardDeleteConfirm: true,
      healthcareCardId: cardId,
      healthcareCardName: cardName,
    });
  };

  closeHealthcareCardDeleteConfirm = () => {
    this.setState({
      showHealthcareCardDeleteConfirm: false,
    });
  };

  deleteHealthcareCard = (success) => {
    doHttpDelete({
      url: healthcareCardUrl,
      params: {
        id: this.state.healthcareCardId,
      },
      onSuccess: (response) => {
        // Close confirmation
        this.closeHealthcareCardDeleteConfirm();
        // Refresh
        this.getHealthcareInfo();
        // Notification
        this.healthcareCardDeleteNotification(response.success);
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
      },
    });
  };

  healthcareCardDeleteNotification = (success) => {
    // Notification
    const notification = this.notificationSystem.current;
    notification.addNotification({
      title: "Healthcare Card Deleted",
      message:
        "Healthcare card '" +
        this.state.healthcareCardName +
        "' was " +
        (success ? "" : "not ") +
        "deleted successfully",
      level: success ? "success" : "error",
      position: "br",
      autoDismiss: 5,
    });
  };

  render() {
    // Info
    const { info, errorMessage } = this.state;
    if (!info) {
      if (errorMessage) {
        return <PageNotFound title="Healthcare" message={errorMessage} />;
      }
      return <div />;
    }

    // Total balance
    let totalBalance = 0.0;

    // Render
    return (
      <div className="page-content">
        <div className="row">
          <div className="col-4">
            <h2 style={{ marginBottom: "15px" }}>
              Healthcare
              <span className="gzn-detail-flag">Feature/Option</span>
            </h2>
            <table className="table table-md table-striped tbl-details">
              <tbody>
                <tr>
                  <td className="label">Budget:</td>
                  <td>
                    <Link to={Links.budgetFor(info.budgetId)}>
                      {info.budgetName}
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td className="label">Feature Enabled:</td>
                  <td>{info.enabled ? "Yes" : "No"}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="col-8">
            <Tabs defaultActiveKey="cards" id="healthcare-tab">
              <Tab
                eventKey="cards"
                title={"Healthcare Cards (" + info.healthcareCards.length + ")"}
              >
                <div className="row">
                  <div
                    className="col-12"
                    style={{
                      textAlign: "right",
                      verticalAlign: "bottom",
                      marginBottom: 10,
                    }}
                  >
                    {info.enabled && (
                      <button
                        className="btn btn-success"
                        onClick={() => this.showHealthcareCardEditor(null)}
                      >
                        <FontAwesomeIcon icon={faPlus} /> &nbsp; Add Healthcare
                        Card
                      </button>
                    )}
                  </div>
                </div>
                <table className="table table-md table-striped tbl-healthcare-cards">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Issuer</th>
                      <th scope="col">Card Number</th>
                      <th scope="col" style={{ width: "75px" }}>
                        Enabled
                      </th>
                      <th scope="col" className="text-right">
                        Balance
                      </th>
                      <th
                        scope="col"
                        style={{ width: "75px", textAlign: "right" }}
                      >
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {info.healthcareCards.map((hc) => {
                      // Add to balance
                      totalBalance += hc.balance;
                      // Render
                      return (
                        <tr
                          key={"hc-" + hc.id}
                          className={classnames("", {
                            "row-selected":
                              hc.id === this.state.healthcareCardId,
                          })}
                        >
                          <th scope="row">
                            <Link to={Links.healthcareCardById(hc.id)}>
                              {hc.name}
                            </Link>
                          </th>
                          <td>{hc.issuer}</td>
                          <td>{hc.number}</td>
                          <td className="text-center">
                            {hc.enabled ? "Yes" : "No"}
                          </td>
                          <td className="text-right">
                            <Money amount={hc.balance} />
                          </td>
                          <td className="text-right">
                            {hc.deletable && (
                              <button
                                type="button"
                                className="btn btn-danger btn-sm"
                                onClick={() =>
                                  this.showHealthcareCardDeleteConfirm(
                                    hc.id,
                                    hc.name
                                  )
                                }
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot className="table-footer">
                    <tr>
                      <th colSpan={4} className="text-right">
                        Total Balance
                      </th>
                      <th className="text-right">
                        {fmtMoneyUSD(totalBalance)}
                      </th>
                      <th></th>
                    </tr>
                  </tfoot>
                </table>
              </Tab>
            </Tabs>
          </div>
        </div>

        <TransactionListings transactions={info.transactions} />

        {this.state.showHealthcareCardEditor && (
          <EditHealthcareCard
            show={this.state.showHealthcareCardEditor}
            onSave={this.onSaveHealthcareCard}
            onClose={this.closeHealthcareEditor}
            healthcareCardId={this.state.healthcareCardId}
          />
        )}

        {this.state.showHealthcareCardDeleteConfirm && (
          <ConfirmDialog
            show={this.state.showHealthcareCardDeleteConfirm}
            handleAction={this.deleteHealthcareCard}
            handleClose={this.closeHealthcareCardDeleteConfirm}
            _type="healthcareCard"
          />
        )}

        <NotificationSystem ref={this.notificationSystem} />
      </div>
    );
  }
}
